import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";


function Products() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Products">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Products"
          Subheader="Products"
          bgimg={`${rpdata?.gallery?.[40]}`}
        />
        
        <div className="m-12 p-6">
        <div class="elfsight-app-c3f7138c-9985-4d65-a387-f92a8ffebcba" data-elfsight-app-lazy></div>
        </div>

      </div>
    </BaseLayout>
  );
}

export default Products;
