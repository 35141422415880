import React, { useContext } from "react";
import { FaCaretRight } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
import BaseLayout from "../global/BaseLayout";
import TransparentHeader from "../global/TransparentHeader";
import { BiPhoneCall, BiCalendar } from 'react-icons/bi'
import { MdOutlineEmail } from 'react-icons/md'
import { FaRegClock } from 'react-icons/fa'
import SocialMedia from "../global/SocialMedia";
import CounterUp from '../global/CounterUp'
import Map from "../Contact/MapContent";
import {FiArrowRight} from 'react-icons/fi'


const bgHeaderImages = [
    'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F63ebc0bf8440a13e777c368d%2Fstock%2FEverything%20For%20Your%20Skin%20Spa-2023-02-14T17%3A11%3A32.482Z-6.jpg?alt=media&token=00ff46f9-72a0-45de-bd98-c03222906bcb',
    'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F63ebc0bf8440a13e777c368d%2Fstock%2FEverything%20For%20Your%20Skin%20Spa-2023-02-14T17%3A11%3A32.483Z-57.jpg?alt=media&token=0a243c5b-99cc-4a33-bf56-6ff5871e30f8',
    'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F63ebc0bf8440a13e777c368d%2Fstock%2FEverything%20For%20Your%20Skin%20Spa-2023-02-14T17%3A11%3A32.482Z-10.jpg?alt=media&token=6aacdb30-695d-4a4b-af98-0d2d57a578ff',
    'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F63ebc0bf8440a13e777c368d%2Fstock%2FEverything%20For%20Your%20Skin%20Spa-2023-02-14T17%3A11%3A32.482Z-24.jpg?alt=media&token=d6f92512-07b1-4515-b342-c73f21edb0f5',
]

function ServicesDetail() {
    const { rpdata } = useContext(GlobalDataContext);
    const { id } = useParams();
    // console.log(id);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <BaseLayout PageName={`${rpdata?.dbPrincipal?.name}`}>
            {
                rpdata?.dbServices?.map((item, index) => {
                    if (item.name.replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-").toLowerCase() === id) {
                        return (
                            <div key={index}>
                                <TransparentHeader
                                    // imagen aleatoria
                                    // bgimg={
                                    //     rpdata?.gallery?.length > 1 ?
                                    //         rpdata?.gallery[Math.floor(Math.random() * rpdata?.gallery?.length)]
                                    //         : rpdata?.stock[Math.floor(Math.random() * rpdata?.stock?.length)]
                                    // }
                                    bgimg={bgHeaderImages[index]}
                                    headertitle={item.name}
                                    Subheader={'Our Services'}
                                />
                                <div className="w-[95%] md:w-[90%] mx-auto my-10 block md:flex md:gap-5">
                                    <div className="w-full md:w-9/12 shadow-lg shadow-neutral-800/30 p-5 md:p-10 mb-5">
                                        <div>
                                            <img
                                                src={item.description[0].img}
                                                alt='no found'
                                                className="w-full object-cover h-auto md:h-[500px]"
                                            />
                                            <h3>
                                                {
                                                    item.name
                                                }
                                            </h3>
                                            {
                                                item.description.map((item, index) => {
                                                    return (
                                                        <p key={index}>{item.text} <br /> <br /></p>

                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                    <div className="w-full md:w-1/4 shadow-lg bgBloqueServicesDetail p-5 md:p-5 mb-5 text-white">
                                        <div className="pb-5">
                                            <h4 className="text-center md:text-start"> Our Services</h4>
                                            <ul className="px-1 md:px-4">
                                                {
                                                    rpdata?.dbServices?.slice(0, 7).map((nam, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <Link
                                                                    to={`/${nam.name.replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-").toLowerCase()}`}
                                                                    onClick={goToTop}
                                                                    className='flex items-center text-white pb-3'
                                                                >
                                                                    <FaCaretRight />
                                                                    <span className="pl-2">
                                                                        {nam.name}
                                                                    </span>
                                                                </Link>
                                                            </li>

                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="pb-5">
                                            <h4 className="text-center md:text-start">Contact</h4>
                                            <ul className="px-1 md:px-4">
                                                {
                                                    rpdata?.dbPrincipal?.phones.map((phone, index) => {
                                                        return (
                                                            <li key={index} className='flex items-center text-white pb-3'>
                                                                <a
                                                                    href={`tel:+1${phone.phone}`}
                                                                    className='flex items-center'
                                                                >
                                                                    <BiPhoneCall />
                                                                    <span className="pl-2">
                                                                        {phone.phone}
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                                {
                                                    rpdata?.dbPrincipal?.emails.map((email, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                className='flex items-center text-white pb-3'
                                                            >
                                                                <a
                                                                    href={`mailto:${email.email}`}
                                                                    className='flex items-center'
                                                                >
                                                                    <MdOutlineEmail />
                                                                    <span className="pl-2">
                                                                        {email.email}
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="pb-5">
                                            <h4 className="text-center md:text-start">Opening Hours</h4>
                                            <ul className="px-1 md:px-4">
                                                <li
                                                    key={index}
                                                    className='flex items-center text-white pb-3'
                                                >
                                                    <BiCalendar />
                                                    <span className="pl-2">
                                                        {rpdata?.dbPrincipal?.workdays?.[0]?.day}
                                                    </span>
                                                </li>
                                                <li
                                                    key={index}
                                                    className='flex items-center text-white pb-3'
                                                >
                                                    <FaRegClock />
                                                    <span className="pl-2">
                                                        {rpdata?.dbPrincipal?.workHours?.[0]?.hour}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        {
                                            rpdata?.dbSocialMedia?.redes?.length > 1 ?
                                                <div className="pb-5 text-center md:text-start">
                                                    <h4 >Follow Us</h4>
                                                    <SocialMedia />
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className="w-4/5 mx-auto flex flex-wrap justify-center items-center gap-5 pb-20">
                                    {
                                        rpdata?.works?.map((works, index) => {
                                            return (
                                                works.service === item.name ?
                                                    <div
                                                        className="border w-full md:w-[30%] rounded-t-full"
                                                        key={index}
                                                    >
                                                        <img
                                                            src={works.gallery[0]}
                                                            alt="not found"
                                                            className="rounded-t-full"
                                                        />
                                                        <p className="font-bold text-center text-[20px] py-5">{works.name}</p>
                                                        <div className="pb-5">
                                                            <a
                                                                href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0].phone}`}
                                                                className="flex items-center justify-center gap-3 font-bold text-[18px] text-[#7AAF40] "
                                                            >
                                                                <span>Book Now</span>
                                                                <FiArrowRight />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    : null

                                            )
                                        })
                                    }
                                </div>
                            </div>
                        );
                    } else return console.log('Servicio no encontrado')

                })
            }

            <CounterUp image={rpdata?.stock?.[26]} />

            <Map />

        </BaseLayout>
    );
}

export default ServicesDetail;