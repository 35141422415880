import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import ServicesBlocks from "./ServicesBlock";
import {FiArrowRight} from 'react-icons/fi'

function ContentServices() {
  const { rpdata } = useContext(GlobalDataContext);
  let classAnterior = "row"
  let classAnterioMargin = 'md:mr-[-40px]'
  return (
    <>
      <div className="w-full">
        <h2 className="text-center mt-10 md:mb-[-100px] mb-[-150px]">{rpdata?.labels?.general?.titleServices}</h2>
        {rpdata?.dbServices?.map((item, i) => {
          return (
            <div className="w-4/5 mx-auto md:my-3 my-5" key={i}>
              {
                item.description.map((service, index) => {
                  let classes = index % 2 === 0 ? "row" : "row-reverse"
                  let nuevaClass = classAnterior === "row" ? "row-reverse" : "row"
                  classes = nuevaClass
                  classAnterior = classes;
                  const titleNone = index === 0 ? ' block' : 'hidden'

                  let classeMargin = index % 2 === 0 ? 'md:mr-[-40px]' : 'md:ml-[-40px]'
                  let nuevaClasseMargin = classAnterioMargin === "md:mr-[-40px]" ? "md:ml-[-40px]" : "md:mr-[-40px]"
                  classeMargin = nuevaClasseMargin
                  classAnterioMargin = classeMargin;

                  return (
                    <ServicesBlocks
                      key={index}
                      ServiceName={item.name}
                      TextService={service.text}
                      itemServices={service.lists.map((list, index) => {
                        return (
                          list.length > 0 ? <li key={index}><i className="fa fa-arrow-right mr-2"></i>{list}</li> : null
                        )
                      })}
                      bgimg={service.img}
                      ClassesRow={classes}
                      DisplayNone={titleNone}
                      classesMargin={classeMargin}
                    />
                  )
                })
              }
              <div className="w-4/5 mx-auto flex flex-wrap justify-center items-center gap-5 pb-20">
                                    {
                                        rpdata?.works?.map((works, index) => {
                                            return (
                                                works.service === item.name ?
                                                    <div
                                                        className="border w-full md:w-[30%] rounded-t-full"
                                                        key={index}
                                                    >
                                                        <img
                                                            src={works.gallery[0]}
                                                            alt="not found"
                                                            className="rounded-t-full"
                                                        />
                                                        <p className="font-bold text-center text-[20px] py-5">{works.name}</p>
                                                        <div className="pb-5">
                                                            <a
                                                                href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0].phone}`}
                                                                className="flex items-center justify-center gap-3 font-bold text-[18px] text-[#7AAF40] "
                                                            >
                                                                <span>Book Now</span>
                                                                <FiArrowRight />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    : null

                                            )
                                        })
                                    }
                                </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
export default ContentServices;